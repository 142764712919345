import "./App.css";
import Landing from "./components/Landing";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import { Route, Switch, BrowserRouter } from "react-router-dom";
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <div className="App">
          <Route path="/" exact={true} component={Landing} />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
