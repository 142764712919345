import React, { useState, useCallback } from "react";
import "./materialSystemDoc.css";
import { useDropzone } from "react-dropzone";
import { LabelText, TextInput, ButtonInput } from "@emerson/dynamic-ui-public";
import { useEffect } from "react";
import { PDFDocument } from "pdf-lib";

const MaterialSystemDoc = (props) => {
  const {
    QuoteData,
    createSRorQuote,
    setConvertedMSDSDoc,
    setProductsMeasured,
    productsMeasured,
    setIsLoading,
    isLoading,
    convertedMSDSDoc,
    errorMsg,
    setStepperDisable,
    setStepMemoize,
    stepMemoize,
  } = props;
  const [uploadedFile, setUploadedFile] = useState([]);
  const [base64Data, setBase64Data] = useState(null);
  const [unsupportedError, setUnsupportedError] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      setUploadedFile([...uploadedFile, ...acceptedFiles]);
    },
    [uploadedFile]
  );
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      if (file.type === "application/pdf") {
        // Function to compress a PDF using pdf-lib
        const compressPDF = async (file) => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);

          return new Promise((resolve, reject) => {
            reader.onload = async () => {
              try {
                const pdfDoc = await PDFDocument.load(reader.result);

                // Example: Compressing the PDF by re-saving it with reduced file size
                const pdfBytes = await pdfDoc.save();

                // Convert compressed bytes to base64
                const base64String = arrayBufferToBase64(pdfBytes);

                resolve({
                  base64: base64String,
                  format: "pdf",
                  mimeType: "application/pdf", // Assuming the mimeType remains the same after compression
                });
              } catch (error) {
                reject(error);
              }
            };

            reader.onerror = (error) => reject(error);
          });
        };

        // Call compressPDF asynchronously within fileToBase64
        (async () => {
          try {
            const compressedResult = await compressPDF(file);
            resolve(compressedResult);
          } catch (error) {
            reject(error);
          }
        })();
      } else {
        setUnsupportedError(
          "Unsupported file type, Please upload only PDF formats"
        );
      }

      reader.onerror = (error) => reject(error);
    });
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  const files = uploadedFile?.map((file, inde) => (
    <p key={file.path}>
      <LabelText text={`${file.path}`} textClass="ddl-text--h6" />
      <span className="linkCustom" onClick={() => remove(inde)}>
        Remove
      </span>
    </p>
  ));

  const remove = (file) => {
    const newFiles = [...files]; // make a var for the new array
    newFiles.splice(file, 1); // remove the file from the array
    setUploadedFile(newFiles);
    setConvertedMSDSDoc(null);
  };

  useEffect(() => {
    setUploadedFile(acceptedFiles);
  }, []);

  useEffect(() => {
    const convertToBase64 = async (IpFile) => {
      const file = IpFile;
      try {
        const { base64, format, mimeType } = await fileToBase64(file);
        setBase64Data({ base64, format, mimeType });
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    };
    uploadedFile.length !== 0
      ? convertToBase64(uploadedFile[0])
      : setBase64Data(null);
  }, [uploadedFile]);

  useEffect(() => {
    base64Data !== null && setConvertedMSDSDoc(base64Data);
  }, [base64Data]);
  useEffect(() => {
    setStepperDisable(true);
    return () => {
      setProductsMeasured("");
    };
  }, []);
  console.log(convertedMSDSDoc, "msds");
  return (
    <div className="materialSysDoc commonDisplaysize">
      <LabelText
        text="Material Safety Documentation"
        textClass="ddl-text--h3"
      />
      <p>
        If you don’t have one already, please{" "}
        <span
          className="linkCustom"
          onClick={() => {
            window.open("https://chemicalsafety.com/sds-search/", "_blank");
          }}
        >
          download
        </span>{" "}
        an MSDS form and upload a completed copy below.
      </p>
      <div>
        <LabelText text="UPLOAD YOUR COMPLETED FORM" textClass="ddl-text--h6" />
        <div className="dropzone">
          {" "}
          <section className="dropContainer">
            <div {...getRootProps({ className: "dropzoneBox" })}>
              <input {...getInputProps()} />
              <p>Drag & Drop an Attachment.</p>
              <p>Allowed formats (.pdf)</p>
              <ButtonInput
                btnType="secondary"
                customClass="dropzoneBtn"
                label="BROWSE FILES "
                onClick={() => {}}
              />
            </div>
            <aside>{files}</aside>
          </section>
        </div>
      </div>
      <div className="ContentDiv">
        <p>
          <strong>Note:</strong> Include your MSDS on the outside of your
          shipping container. Non-conformance to the requirements below may
          result in refusal of shipment. Please remove batteries before shipping
          wireless devices.
        </p>
        <p>
          <strong>Cleaning & MSDS requirements:</strong> All equipment sent to
          measurement solutions systems & services must be sent clean. Supply
          the required MSDS to identify any substances the equipment could have
          been exposed to.
        </p>
      </div>
      <TextInput
        label="Process Type"
        onChange={(e) => {
          setProductsMeasured(e.target.value);
        }}
        required
      />
      <p className="errorText">{errorMsg}</p>
      <div className="buttonNext">
        <ButtonInput
          btnType=""
          label="CONTINUE"
          onClick={() => {
            createSRorQuote(QuoteData, "SupportRequest");
            setIsLoading(true);
          }}
          disabled={
            productsMeasured === ""
              ? true
              : convertedMSDSDoc !== null && isLoading === false
              ? false
              : convertedMSDSDoc !== null && isLoading === true
              ? true
              : convertedMSDSDoc === null && isLoading === false && true
          }
        />
      </div>
    </div>
  );
};

export default MaterialSystemDoc;
