import React, { useEffect, useState } from "react";
import { LabelText, ButtonInput } from "@emerson/dynamic-ui-public";
import ResponseTime from "../../assets/responseTime.png";
import PinIco from "../../assets/requestStatus.png";
import SupportMan from "../../assets/supportMan.png";
import "./SubmitRequest.css";
import { useHistory } from "react-router-dom";

const SubmitRequest = (props) => {
  const history = useHistory();
  const { activeInStep, setActiveInStep, serviceTypeName, setStepperDisable } =
    props;
  useEffect(() => {
    setStepperDisable(false);
  }, []);
  return (
    <>
      <div className="SubmitRequestFinal">
        <div className="SubmitContent">
          <LabelText text="You're all set!" textClass="ddl-text--h3" />
          {serviceTypeName === "Request Field Service" && (
            <LabelText
              // text="Thank you for choosing Emerson for your service needs. Here's what's next:"
              text="Thank you for choosing Emerson for your service needs. Someone will reach out to you in 1-2 business days."
              textClass="ddl-text--p"
            />
          )}
          {serviceTypeName === "Return A Product" && (
            <LabelText
              text="Thank you for choosing Emerson for your service needs. Someone will reach out to you in 1-2 business days."
              textClass="ddl-text--p"
            />
          )}
        </div>
        {serviceTypeName === "Request Field Service" && (
          <div className="submitOptions">
            {/* <div className="ValueOptions">
              <img src={ResponseTime} alt="Icon" />
              <LabelText
                text="Expected Response Time"
                textClass="ddl-text--h5"
              />
              <LabelText
                text="Your expected response time is TBD"
                textClass="ddl-text--p"
              />
            </div> */}
            {/* <div className="ValueOptions">
              <img src={PinIco} alt="Icon" className="pinIcon" />
              <LabelText text="Request Status" textClass="ddl-text--h5" />
              <LabelText
                text="You can locate the status of your request"
                textClass="ddl-text--p"
              />
              <p className="linkCustom">here</p>
            </div> */}
            {/* <div className="ValueOptions">
              <img src={SupportMan} alt="Icon" className="supportMan1" />
              <LabelText
                text="Further Support Info"
                textClass="ddl-text--h5"
              />
              <LabelText
                text="Your personal contact for further support is TBD"
                textClass="ddl-text--p"
              />
            </div> */}
          </div>
        )}
        <ButtonInput
          btnType=""
          label="SUBMIT ANOTHER REQUEST"
          onClick={() => {
            history.go("/");
          }}
          showBackIcon
        />
      </div>
    </>
  );
};

export default SubmitRequest;
