import React, { useEffect, useState } from "react";
import "./user.css";
import useOnScreen from "../../customHooks/UseOnScreen";

import { LabelText, ButtonInput } from "@emerson/dynamic-ui-public";
const User = (props) => {
  const {
    setActiveInStep,
    logInStatus,
    setStepperDisable,
    stepMemoize,
    setServiceName,
  } = props;
  logInStatus !== undefined && setActiveInStep(1), setServiceName("");
  useEffect(() => {
    setStepperDisable(true);
  }, []);
  // visibility check
  const handleVisibilityChange = () => {
    if (stepMemoize === 2) {
      setServiceName("");
      setActiveInStep(1);
    }
  };
  const ref = useOnScreen(handleVisibilityChange);
  //visibilty check
  return (
    <>
      <div className="userContainer" ref={ref}>
        <LabelText text="Customer Login" textClass="ddl-text--h3" />
        <p>
          We value your time. To help us expedite your experience, please sign
          in to your account.
          <br />
          <br />
        </p>
        <p>
          The sign in button will redirect you to the MyEmerson Sign In page.
          <br />
          <br />
          <br />
        </p>
        <div>
          <ButtonInput
            btnType=""
            label="SIGN IN"
            onClick={() => {
              logInStatus === undefined
                ? (window.location.href = `${process.env.REACT_APP_BASEURL_SELF}/catalog/LogonForm?locale=en-us&myAcctMain=1&catalogId=20051&deleteCartCookie=true&rememberMe=&langId=-1&storeId=20151&externalUrl=${process.env.REACT_APP_API_URL_NODE}`)
                : setActiveInStep(1);
              // setActiveInStep(1);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default User;
