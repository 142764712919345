const EmailData = {
  "North America": {
    Quote: "EmersonLLLPQuotes@ocmxchange.emerson.com",
    SupportRequest: "SupportSupportRequest@ocmxchange.emerson.com",
  },
  "Asia Pacific": {
    INDIA: {
      Quote: "AsiaIndiaMumbaiQuotes@ocmxchange.emerson.com",
      SupportRequest: "AsiaIndiaMumbaiSalesOrders@ocmxchange.emerson.com",
    },
    SINGAPORE: {
      Quote: "AsiaSingaporeQuotes@ocmxchange.emerson.com",
      SupportRequest: "AsiaSingaporeSalesOrders@ocmxchange.emerson.com",
    },
  },
  "Middle East and Africa": {
    DUBAI: {
      Quote: "MEAUAEDubaiQuote@ocmxchange.emerson.com",
      SupportRequest: "MEAUAEDubaiSalesOrders@ocmxchange.emerson.com",
    },
    DAR_AL_IBTIKAR: {
      Quote: "MEAIraqBasraQuote@ocmxchange.emerson.com",
      SupportRequest: "MEAIraqBasraSalesOrders@ocmxchange.emerson.com",
    },
    KUWAIT: {
      Quote: "MEAKuwaitKuwaitCityQuote@ocmxchange.emerson.com",
      SupportRequest: "MEAKuwaitKuwaitCitySalesOrders@ocmxchange.emerson.com",
    },
    MOROCCO: {
      Quote: "MEANAMoroccoQuote@ocmxchange.emerson.com",
      SupportRequest: "MEANAMoroccoSalesOrders@ocmxchange.emerson.com",
    },
    QATAR: {
      Quote: "MEAQatarDohaQuote@ocmxchange.emerson.com",
      SupportRequest: "MEAQatarDohaSalesOrders@ocmxchange.emerson.com",
    },
    "SAUDI ARABIA": {
      Quote: "MEASAAlKhobarQuote@ocmxchange.emerson.com",
      SupportRequest: "MEASAAlKhobarSalesOrders@ocmxchange.emerson.com",
    },
    "ABU DHABI": {
      Quote: "MEAUAEAbuDhabiQuote@ocmxchange.emerson.com",
      SupportRequest: "MEAUAEAbuDhabiSalesOrders@ocmxchange.emerson.com",
    },
    NIGERIA: {
      Quote: "MEAWANigeriaQuote@ocmxchange.emerson.com",
      SupportRequest: "MEAWANigeriaSalesOrders@ocmxchange.emerson.com",
    },
  },
};
export default EmailData;
