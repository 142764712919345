export const locationList = [
  {
    Id: 300000389439187,
    RecordName: "Actuation Technologies - BU Headquarters",
  },
  {
    Id: 300000300481601,
    RecordName: "Actuation Technologies Factory - Bhosari,IN",
  },
  {
    Id: 300003058121052,
    RecordName: "Actuation Technologies Factory - Chennai,IN",
  },
  {
    Id: 300000300481604,
    RecordName: "Actuation Technologies Factory - Dubai,AE",
  },
  {
    Id: 300000300481590,
    RecordName: "Actuation Technologies Factory - Fiorenzuola,IT",
  },
  {
    Id: 300000300481585,
    RecordName: "Actuation Technologies Factory - Houston,US",
  },
  {
    Id: 300000300481584,
    RecordName: "Actuation Technologies Factory - Mansfield,US",
  },
  {
    Id: 300000300481594,
    RecordName: "Actuation Technologies Factory - Peterlee,GB",
  },
  {
    Id: 300000300481603,
    RecordName: "Actuation Technologies Factory - Singapore,SG",
  },
  {
    Id: 300000300481588,
    RecordName: "Actuation Technologies Factory - Szekesfehervar,HU",
  },
  {
    Id: 300000300481599,
    RecordName: "Actuation Technologies Factory - WuQing,CN",
  },
  {
    Id: 300003092607193,
    RecordName: "AE Valves Factory - Verviers,BE",
  },
  {
    Id: 300000047559034,
    RecordName: "AFTC Factory - Nanjing,CN",
  },
  {
    Id: 300000047559012,
    RecordName: "AP Customer Support Center - Nanjing,CN",
  },
  {
    Id: 300002509780273,
    RecordName: "AP Customer Support Center - Chennai, IN",
  },
  {
    Id: 300002509780272,
    RecordName: "AP Customer Support Center - Nasik, IN",
  },
  {
    Id: 300002509780271,
    RecordName: "AP Customer Support Center - Noida, IN",
  },
  {
    Id: 300002509780270,
    RecordName: "AP Customer Support Center - Pune, IN",
  },
  {
    Id: 300000206296960,
    RecordName:
      "AP Customer Support Center Actuation Technologies Quality - Quezon City,PH",
  },
  {
    Id: 300000047559014,
    RecordName: "AP Customer Support Center EMSS - Quezon City,PH",
  },
  {
    Id: 300002509780275,
    RecordName: "AP Customer Support Center FRSI - Pasig City, PH",
  },
  {
    Id: 300000127931338,
    RecordName: "AP Customer Support Center FSG - Manila,PH",
  },
  {
    Id: 300002509780284,
    RecordName: "AP Customer Support Center Gul - Singapore, SG",
  },
  {
    Id: 300000127931358,
    RecordName: "AP Customer Support Center MANZAT - Manila,PH",
  },
  {
    Id: 300000127931339,
    RecordName: "AP Customer Support Center MEAS - Manila,PH",
  },
  {
    Id: 300002509780278,
    RecordName: "AP Customer Support Center Pandan - Singapore, SG",
  },
  {
    Id: 300000127931360,
    RecordName: "AP Customer Support Center RMT - Xian,CN",
  },
  {
    Id: 300002509780276,
    RecordName: "AP Gul Service Center - Singapore, SG",
  },
  {
    Id: 300000110981006,
    RecordName: "AP Sales Office Hiroshima,JP",
  },
  {
    Id: 300000171294500,
    RecordName: "AP Sales Office JB,MY",
  },
  {
    Id: 300000137071505,
    RecordName: "AP Sales Office New Delhi,IN",
  },
  {
    Id: 300002509780277,
    RecordName: "AP Sales Office Ang Mo Kio Singapore, SG",
  },
  {
    Id: 300001934984502,
    RecordName: "AP Sales Office Auckland, NZ",
  },
  {
    Id: 300000119636080,
    RecordName: "AP Sales Office Beijing,CN",
  },
  {
    Id: 300003326938088,
    RecordName: "AP Sales Office Brisbane,AU",
  },
  {
    Id: 300000130602744,
    RecordName: "AP Sales Office Busan,KR",
  },
  {
    Id: 300000137071502,
    RecordName: "AP Sales Office Central Indore ,IN",
  },
  {
    Id: 300000137071503,
    RecordName: "AP Sales Office Central Surat ,IN",
  },
  {
    Id: 300000139053072,
    RecordName: "AP Sales Office Central Vadodara ,IN",
  },
  {
    Id: 300000110981003,
    RecordName: "AP Sales Office Chiba,JP",
  },
  {
    Id: 300000110981001,
    RecordName: "AP Sales Office Chubu,JP",
  },
  {
    Id: 300000136312809,
    RecordName: "AP Sales Office Chukai,MY",
  },
  {
    Id: 300000171294503,
    RecordName: "AP Sales Office Cross BU Miri,MY",
  },
  {
    Id: 300000171294501,
    RecordName: "AP Sales Office Cross BU Penang,MY",
  },
  {
    Id: 300000171294504,
    RecordName: "AP Sales Office Cross BU Subang,MY",
  },
  {
    Id: 300000119644884,
    RecordName: "AP Sales Office Daesan,KR",
  },
  {
    Id: 300000176898915,
    RecordName: "AP Sales Office Eagle Farm, AU",
  },
  {
    Id: 300000137071506,
    RecordName: "AP Sales Office East Kolkata ,IN",
  },
  {
    Id: 300000110981018,
    RecordName: "AP Sales Office EPC Tokyo,JP",
  },
  {
    Id: 300000175613101,
    RecordName: "AP Sales Office FCTL Chukai,MY",
  },
  {
    Id: 300000171294506,
    RecordName: "AP Sales Office FCTL JB,MY",
  },
  {
    Id: 300000175613107,
    RecordName: "AP Sales Office FCTL Miri,MY",
  },
  {
    Id: 300000171294535,
    RecordName: "AP Sales Office FCTL Penang,MY",
  },
  {
    Id: 300000127931337,
    RecordName: "AP Sales Office FCTL Singapore,SG",
  },
  {
    Id: 300000171294505,
    RecordName: "AP Sales Office FCTL Subang,MY",
  },
  {
    Id: 300000136312816,
    RecordName: "AP Sales Office Flow Solutions Group Subang,MY",
  },
  {
    Id: 300000175613106,
    RecordName: "AP Sales Office FSG Chukai,MY",
  },
  {
    Id: 300000171294524,
    RecordName: "AP Sales Office FSG JB,MY",
  },
  {
    Id: 300000175613111,
    RecordName: "AP Sales Office FSG Miri,MY",
  },
  {
    Id: 300000175613100,
    RecordName: "AP Sales Office FSG Penang,MY",
  },
  {
    Id: 300000127931334,
    RecordName: "AP Sales Office FSG Singapore,SG",
  },
  {
    Id: 300002745939516,
    RecordName: "AP Sales Office Fukuoka,JP",
  },
  {
    Id: 300002509780283,
    RecordName: "AP Sales Office Gul Singapore, SG",
  },
  {
    Id: 300003221319638,
    RecordName: "AP Sales Office Himeji,JP",
  },
  {
    Id: 300000047558984,
    RecordName: "AP Sales Office Ho Chi Minh, VN",
  },
  {
    Id: 300000141759835,
    RecordName: "AP Sales Office India,IN",
  },
  {
    Id: 300000120281663,
    RecordName: "AP Sales Office ISV East,AU",
  },
  {
    Id: 300000119835724,
    RecordName: "AP Sales Office ISV LBP_INT Brisbane,AU",
  },
  {
    Id: 300000120281661,
    RecordName: "AP Sales Office ISV North,AU",
  },
  {
    Id: 300000120281662,
    RecordName: "AP Sales Office ISV South,AU",
  },
  {
    Id: 300000120281664,
    RecordName: "AP Sales Office ISV West,AU",
  },
  {
    Id: 300000110981017,
    RecordName: "AP Sales Office Kashima,JP",
  },
  {
    Id: 300002733381836,
    RecordName: "AP Sales Office Kobe,JP",
  },
  {
    Id: 300000119636002,
    RecordName: "AP Sales Office Kyushu,JP",
  },
  {
    Id: 300000137071507,
    RecordName: "AP Sales Office Maharashtra Aurangabad ,IN",
  },
  {
    Id: 300000137071508,
    RecordName: "AP Sales Office Maharashtra Nagpur ,IN",
  },
  {
    Id: 300000139053073,
    RecordName: "AP Sales Office Maharashtra Pune ,IN",
  },
  {
    Id: 300000137071509,
    RecordName: "AP Sales Office Maharashtra Raipur ,IN",
  },
  {
    Id: 300000119835731,
    RecordName: "AP Sales Office MANZAT DOCUMENTATION, AU",
  },
  {
    Id: 300000119835725,
    RecordName: "AP Sales Office MANZAT MRO, AU",
  },
  {
    Id: 300000119835730,
    RecordName: "AP Sales Office MANZAT POST BOOKING, AU",
  },
  {
    Id: 300000119835726,
    RecordName: "AP Sales Office MANZAT PROJECT, AU",
  },
  {
    Id: 300000261996410,
    RecordName: "AP Sales Office MEAS Busan,KR",
  },
  {
    Id: 300000261996411,
    RecordName: "AP Sales Office MEAS Daesan,KR",
  },
  {
    Id: 300000127931357,
    RecordName: "AP Sales Office MEAS Singapore,SG",
  },
  {
    Id: 300000261996412,
    RecordName: "AP Sales Office MEAS Ulsan,KR",
  },
  {
    Id: 300000261996413,
    RecordName: "AP Sales Office MEAS Yeosu,KR",
  },
  {
    Id: 300000261996414,
    RecordName: "AP Sales Office MEAS Yongin,KR",
  },
  {
    Id: 300000047558970,
    RecordName: "AP Sales Office Melbourne,AU",
  },
  {
    Id: 300000136312808,
    RecordName: "AP Sales Office Miri,MY",
  },
  {
    Id: 300000110981005,
    RecordName: "AP Sales Office Mizushima,JP",
  },
  {
    Id: 300002733691557,
    RecordName: "AP Sales Office Nagoya,JP",
  },
  {
    Id: 300000139053067,
    RecordName: "AP Sales Office National ,IN",
  },
  {
    Id: 300000202691245,
    RecordName: "AP Sales Office North Chandigarh, IN",
  },
  {
    Id: 300000137071510,
    RecordName: "AP Sales Office North Delhi ,IN",
  },
  {
    Id: 300000139053069,
    RecordName: "AP Sales Office North Gurgaon ,IN",
  },
  {
    Id: 300000137071511,
    RecordName: "AP Sales Office North Kota ,IN",
  },
  {
    Id: 300000137071512,
    RecordName: "AP Sales Office North Lucknow ,IN",
  },
  {
    Id: 300000137071539,
    RecordName: "AP Sales Office North Noida ,IN",
  },
  {
    Id: 300000047558976,
    RecordName: "AP Sales Office Osaka,JP",
  },
  {
    Id: 300000047558982,
    RecordName: "AP Sales Office Pandan Singapore, SG",
  },
  {
    Id: 300000136312811,
    RecordName: "AP Sales Office Penang,MY",
  },
  {
    Id: 300000136312812,
    RecordName: "AP Sales Office Permasense Subang,MY",
  },
  {
    Id: 300003326958605,
    RecordName: "AP Sales Office Perth,AU",
  },
  {
    Id: 300000175613102,
    RecordName: "AP Sales Office PSS Chukai,MY",
  },
  {
    Id: 300000171294507,
    RecordName: "AP Sales Office PSS JB,MY",
  },
  {
    Id: 300000175613108,
    RecordName: "AP Sales Office PSS Miri,MY",
  },
  {
    Id: 300000172769544,
    RecordName: "AP Sales Office PSS Penang,MY",
  },
  {
    Id: 300000127931335,
    RecordName: "AP Sales Office PSS Singapore,SG",
  },
  {
    Id: 300000175613112,
    RecordName: "AP Sales Office PSS Subang,MY",
  },
  {
    Id: 300000120281666,
    RecordName: "AP Sales Office PWS KOB1&2 Yongin,KR",
  },
  {
    Id: 300000120281667,
    RecordName: "AP Sales Office PWS KOB3 Busan,KR",
  },
  {
    Id: 300000120281668,
    RecordName: "AP Sales Office PWS KOB3 Yeosu,KR",
  },
  {
    Id: 300000120281665,
    RecordName: "AP Sales Office PWS KOB3 Yongin,KR",
  },
  {
    Id: 300000130602745,
    RecordName: "AP Sales Office PWS Order Yongin,KR",
  },
  {
    Id: 300000127931336,
    RecordName: "AP Sales Office PWS Singapore,SG",
  },
  {
    Id: 300000175613103,
    RecordName: "AP Sales Office RAI Chukai,MY",
  },
  {
    Id: 300000171294508,
    RecordName: "AP Sales Office RAI JB,MY",
  },
  {
    Id: 300000175613109,
    RecordName: "AP Sales Office RAI Miri,MY",
  },
  {
    Id: 300000175613098,
    RecordName: "AP Sales Office RAI Penang,MY",
  },
  {
    Id: 300000047558986,
    RecordName: "AP Sales Office Rayong,TH",
  },
  {
    Id: 300000175613105,
    RecordName: "AP Sales Office RMT Chukai,MY",
  },
  {
    Id: 300000171294521,
    RecordName: "AP Sales Office RMT JB,MY",
  },
  {
    Id: 300000175613110,
    RecordName: "AP Sales Office RMT Miri,MY",
  },
  {
    Id: 300000175613099,
    RecordName: "AP Sales Office RMT Penang,MY",
  },
  {
    Id: 300000136312807,
    RecordName: "AP Sales Office RMT Subang,MY",
  },
  {
    Id: 300000136312815,
    RecordName: "AP Sales Office Rosemount Analytical Subang,MY",
  },
  {
    Id: 300000136312814,
    RecordName: "AP Sales Office Rosemount Measurement Subang,MY",
  },
  {
    Id: 300003363841946,
    RecordName: "AP Sales Office Sakura,JP",
  },
  {
    Id: 300000047558980,
    RecordName: "AP Sales Office Selangor,MY",
  },
  {
    Id: 300000047558972,
    RecordName: "AP Sales Office Shanghai ,CN",
  },
  {
    Id: 300000137071513,
    RecordName: "AP Sales Office South Bangalore ,IN",
  },
  {
    Id: 300000137071514,
    RecordName: "AP Sales Office South Chennai ,IN",
  },
  {
    Id: 300000139053070,
    RecordName: "AP Sales Office South Hyderabad ,IN",
  },
  {
    Id: 300000137071515,
    RecordName: "AP Sales Office South Kochi ,IN",
  },
  {
    Id: 300000137071516,
    RecordName: "AP Sales Office South Visakhapatnam ,IN",
  },
  {
    Id: 300000110981016,
    RecordName: "AP Sales Office Tohoku,JP",
  },
  {
    Id: 300000110981004,
    RecordName: "AP Sales Office Tokyo,JP",
  },
  {
    Id: 300002745923642,
    RecordName: "AP Sales Office Ueno Tokyo,JP",
  },
  {
    Id: 300000119644882,
    RecordName: "AP Sales Office Ulsan,KR",
  },
  {
    Id: 300000137071517,
    RecordName: "AP Sales Office West Goa ,IN",
  },
  {
    Id: 300000139053068,
    RecordName: "AP Sales Office West Mumbai ,IN",
  },
  {
    Id: 300000047558978,
    RecordName: "AP Sales Office Yeosu,KR",
  },
  {
    Id: 300000119644881,
    RecordName: "AP Sales Office Yongin,KR",
  },
  {
    Id: 300000206296964,
    RecordName: "AP Sales Office, HK",
  },
  {
    Id: 300000047558883,
    RecordName: "Asia Pacific Headquarters - AUTOSOL",
  },
  {
    Id: 300000389439193,
    RecordName: "Assembly Technologies - BU Headquarters",
  },
  {
    Id: 300000047559032,
    RecordName: "BMMC Factory - Nanjing,CN",
  },
  {
    Id: 300003046759738,
    RecordName: "BMMC/EBIC Factory - Beijing,CN",
  },
  {
    Id: 300000389439172,
    RecordName: "Discrete & Industrial Headquarters",
  },
  {
    Id: 300002733691477,
    RecordName: "EFC Factory - Moenchengladbach,DE",
  },
  {
    Id: 300000659454560,
    RecordName: "Emerson Automation Solutions Headquarters",
  },
  {
    Id: 300000047562891,
    RecordName: "Emerson Corporate Headquarters",
  },
  {
    Id: 300002733710346,
    RecordName: "EUR Customer Support Center - Szekesfehervar,HU",
  },
  {
    Id: 300000047559008,
    RecordName: "EUR Customer Support Center - Cluj Napoca,RO",
  },
  {
    Id: 300000659454593,
    RecordName: "EUR Customer Support Center FLMC - Brugg, CH",
  },
  {
    Id: 300000659454591,
    RecordName: "EUR Customer Support Center FLMC - Castro, ES",
  },
  {
    Id: 300000389439267,
    RecordName: "EUR Customer Support Center FLMC - Durrn, DE",
  },
  {
    Id: 300000659454590,
    RecordName: "EUR Customer Support Center FLMC - Eger, HU",
  },
  {
    Id: 300000389439265,
    RecordName: "EUR Customer Support Center FLMC - Laatzen, DE",
  },
  {
    Id: 300000659454589,
    RecordName: "EUR Customer Support Center FLMC - Lodz, PL",
  },
  {
    Id: 300000389439262,
    RecordName: "EUR Customer Support Center FLMC - Luce, FR",
  },
  {
    Id: 300000659454592,
    RecordName: "EUR Customer Support Center FLMC - Malmo, SE",
  },
  {
    Id: 300000047558954,
    RecordName: "EUR Sales Office Kyiv,UA",
  },
  {
    Id: 300000047558924,
    RecordName: "EUR Sales Office Saint-Priest,FR",
  },
  {
    Id: 300000293400461,
    RecordName: "EUR Sales Office ,BY",
  },
  {
    Id: 300000047558950,
    RecordName: "EUR Sales Office Baar,CH",
  },
  {
    Id: 300000659454561,
    RecordName: "EUR Sales Office Baku, AZ",
  },
  {
    Id: 300002509780515,
    RecordName: "EUR Sales Office Belarus Minsk, BY",
  },
  {
    Id: 300000047558944,
    RecordName: "EUR Sales Office Bratislava,SK",
  },
  {
    Id: 300000389439253,
    RecordName: "EUR Sales Office Breda, NL",
  },
  {
    Id: 300000047558928,
    RecordName: "EUR Sales Office Budapest,HU",
  },
  {
    Id: 300000148186843,
    RecordName: "EUR Sales Office Center & South Moscow, RU",
  },
  {
    Id: 300002509780512,
    RecordName: "EUR Sales Office Center & South Rostov on Don, RU",
  },
  {
    Id: 300001106712909,
    RecordName: "EUR Sales Office Cergy, FR",
  },
  {
    Id: 300001106712910,
    RecordName: "EUR Sales Office Cernay Cedex, FR",
  },
  {
    Id: 300001106712911,
    RecordName: "EUR Sales Office Chartres, FR",
  },
  {
    Id: 300000047558920,
    RecordName: "EUR Sales Office Copenhagen,DK",
  },
  {
    Id: 300003172528914,
    RecordName: "EUR Sales Office Cork,IE",
  },
  {
    Id: 300000047558911,
    RecordName: "EUR Sales Office Diegem,BE",
  },
  {
    Id: 300000659454609,
    RecordName: "EUR Sales Office Far East Yuzhno-Sakhalinsk, RU",
  },
  {
    Id: 300000389439250,
    RecordName: "EUR Sales Office FLMC Aschheim, DE",
  },
  {
    Id: 300000659454579,
    RecordName: "EUR Sales Office FLMC Barcelona, ES",
  },
  {
    Id: 300000659454572,
    RecordName: "EUR Sales Office FLMC Boxtel, NL",
  },
  {
    Id: 300000659454565,
    RecordName: "EUR Sales Office FLMC Brno, CZ",
  },
  {
    Id: 300000659454563,
    RecordName: "EUR Sales Office FLMC Brussels, BE",
  },
  {
    Id: 300000659454569,
    RecordName: "EUR Sales Office FLMC Budapest, HU",
  },
  {
    Id: 300000659454570,
    RecordName: "EUR Sales Office FLMC Bussero, IT",
  },
  {
    Id: 300000659454578,
    RecordName: "EUR Sales Office FLMC Castro, ES",
  },
  {
    Id: 300000659454571,
    RecordName: "EUR Sales Office FLMC Ede, NL",
  },
  {
    Id: 300000389439247,
    RecordName: "EUR Sales Office FLMC Felbach, DE",
  },
  {
    Id: 300000659454581,
    RecordName: "EUR Sales Office FLMC Gothenburg, SE",
  },
  {
    Id: 300000389439249,
    RecordName: "EUR Sales Office FLMC Haan, DE",
  },
  {
    Id: 300000389439251,
    RecordName: "EUR Sales Office FLMC Hamburg, DE",
  },
  {
    Id: 300000659454566,
    RecordName: "EUR Sales Office FLMC Helsinki, FI",
  },
  {
    Id: 300000659454585,
    RecordName: "EUR Sales Office FLMC Istanbul, TR",
  },
  {
    Id: 300000659454582,
    RecordName: "EUR Sales Office FLMC Karlstad, SE",
  },
  {
    Id: 300000659454568,
    RecordName: "EUR Sales Office FLMC Laatzen, DE",
  },
  {
    Id: 300000659454584,
    RecordName: "EUR Sales Office FLMC Lachen, CH",
  },
  {
    Id: 300000659454562,
    RecordName: "EUR Sales Office FLMC Linz, AT",
  },
  {
    Id: 300000389439229,
    RecordName: "EUR Sales Office FLMC Luce, FR",
  },
  {
    Id: 300000659454576,
    RecordName: "EUR Sales Office FLMC Moscow, RU",
  },
  {
    Id: 300000659454577,
    RecordName: "EUR Sales Office FLMC Nove Mesto, SK",
  },
  {
    Id: 300000659454575,
    RecordName: "EUR Sales Office FLMC Oporto, PT",
  },
  {
    Id: 300000659454573,
    RecordName: "EUR Sales Office FLMC Oslo, NO",
  },
  {
    Id: 300000659454564,
    RecordName: "EUR Sales Office FLMC Prague, CZ",
  },
  {
    Id: 300000659454586,
    RecordName: "EUR Sales Office FLMC Skelmersdale, GB",
  },
  {
    Id: 300000659454580,
    RecordName: "EUR Sales Office FLMC Stockholm, SE",
  },
  {
    Id: 300000659454567,
    RecordName: "EUR Sales Office FLMC Stovring, DK",
  },
  {
    Id: 300000659454583,
    RecordName: "EUR Sales Office FLMC Sunsvall, SE",
  },
  {
    Id: 300000659454574,
    RecordName: "EUR Sales Office FLMC Warsaw, PL",
  },
  {
    Id: 300000047558922,
    RecordName: "EUR Sales Office Helsinki,FI",
  },
  {
    Id: 300000211800077,
    RecordName: "EUR Sales Office Iberia,ES",
  },
  {
    Id: 300000047558952,
    RecordName: "EUR Sales Office Istanbul,TR",
  },
  {
    Id: 300000047558948,
    RecordName: "EUR Sales Office Karlstad,SE",
  },
  {
    Id: 300000148186849,
    RecordName: "EUR Sales Office Kazakhstan ,KZ",
  },
  {
    Id: 300000389439238,
    RecordName: "EUR Sales Office Korschenbroich, DE",
  },
  {
    Id: 300000047558926,
    RecordName: "EUR Sales Office Langenfeld,DE",
  },
  {
    Id: 300000047558938,
    RecordName: "EUR Sales Office Lisbon,PT",
  },
  {
    Id: 300001106712915,
    RecordName: "EUR Sales Office Lucca, IT",
  },
  {
    Id: 300001106712912,
    RecordName: "EUR Sales Office Lugagnano Val d'Arda, IT",
  },
  {
    Id: 300000047558946,
    RecordName: "EUR Sales Office Madrid,ES",
  },
  {
    Id: 300000389439236,
    RecordName: "EUR Sales Office Moenchengladbach, DE",
  },
  {
    Id: 300000148186846,
    RecordName: "EUR Sales Office NorthWest St. Petersburg, RU",
  },
  {
    Id: 300000047558934,
    RecordName: "EUR Sales Office Porsgrun,NO",
  },
  {
    Id: 300000047558918,
    RecordName: "EUR Sales Office Prague,CZ",
  },
  {
    Id: 300001106712916,
    RecordName: "EUR Sales Office Rescaldina, IT",
  },
  {
    Id: 300000047558932,
    RecordName: "EUR Sales Office Rijswijk,NL",
  },
  {
    Id: 300000047558942,
    RecordName: "EUR Sales Office Russia Moscow, RU",
  },
  {
    Id: 300000047558940,
    RecordName: "EUR Sales Office SEE Bucharest,RO",
  },
  {
    Id: 300000047558930,
    RecordName: "EUR Sales Office Seregno,IT",
  },
  {
    Id: 300002509780513,
    RecordName: "EUR Sales Office Siberia Krasnoyarsk, RU",
  },
  {
    Id: 300000148186847,
    RecordName: "EUR Sales Office Siberia Novosibirsk, RU",
  },
  {
    Id: 300001106712917,
    RecordName: "EUR Sales Office Skui, NO",
  },
  {
    Id: 300000047558913,
    RecordName: "EUR Sales Office Sofia,BG",
  },
  {
    Id: 300000047558956,
    RecordName: "EUR Sales Office Stockport,GB",
  },
  {
    Id: 300002534480720,
    RecordName: "EUR Sales Office Ural Chelyabinsk, RU",
  },
  {
    Id: 300000148186848,
    RecordName: "EUR Sales Office Ural Tyumen, RU",
  },
  {
    Id: 300000047558908,
    RecordName: "EUR Sales Office Vienna,AT",
  },
  {
    Id: 300002509780516,
    RecordName: "EUR Sales Office Volga Kazan, RU",
  },
  {
    Id: 300002509780514,
    RecordName: "EUR Sales Office Volga Samara, RU",
  },
  {
    Id: 300000047558936,
    RecordName: "EUR Sales Office Warsaw,PL",
  },
  {
    Id: 300000389439237,
    RecordName: "EUR Sales Office Wiehl-Marienhage, DE",
  },
  {
    Id: 300000047558916,
    RecordName: "EUR Sales Office Zagreb,HR",
  },
  {
    Id: 300000047558878,
    RecordName: "Europe Headquarters - AUTOSOL",
  },
  {
    Id: 300000136312813,
    RecordName: "Factory - Nilai,MY",
  },
  {
    Id: 300000389439180,
    RecordName: "Final Control Headquarters",
  },
  {
    Id: 300000047558895,
    RecordName: "FLMC Global - BU Headquarters",
  },
  {
    Id: 300000389439188,
    RecordName: "Flow Controls - BU Headquarters",
  },
  {
    Id: 300000261996409,
    RecordName: "Flow Controls Factory - Marshalltown,US",
  },
  {
    Id: 300000211800081,
    RecordName: "Flow Factory - Cluj Napoca,RO",
  },
  {
    Id: 300000211804233,
    RecordName: "Flow Factory - EDE,NL",
  },
  {
    Id: 300000389439198,
    RecordName: "Flow Solutions - BU Headquarters",
  },
  {
    Id: 300000389439184,
    RecordName: "Flow Solutions Headquarters",
  },
  {
    Id: 300000389439213,
    RecordName: "Fluid & Motion Control - Head Office",
  },
  {
    Id: 300000389439340,
    RecordName: "Fluid & Motion Control Factory - Bonneville,FR",
  },
  {
    Id: 300000659454599,
    RecordName: "Fluid & Motion Control Factory - Boxtel,NL",
  },
  {
    Id: 300000659454596,
    RecordName: "Fluid & Motion Control Factory - Brussels,BE",
  },
  {
    Id: 300000659454598,
    RecordName: "Fluid & Motion Control Factory - Bussero,IT",
  },
  {
    Id: 300000659454603,
    RecordName: "Fluid & Motion Control Factory - Castro,ES",
  },
  {
    Id: 300000659454602,
    RecordName: "Fluid & Motion Control Factory - Chelyabinsk,RU",
  },
  {
    Id: 300000659454607,
    RecordName: "Fluid & Motion Control Factory - Dubai,AE",
  },
  {
    Id: 300000389439347,
    RecordName: "Fluid & Motion Control Factory - Durrn,DE",
  },
  {
    Id: 300000659454600,
    RecordName: "Fluid & Motion Control Factory - Ede,NL",
  },
  {
    Id: 300000659454597,
    RecordName: "Fluid & Motion Control Factory - Eger,HU",
  },
  {
    Id: 300000659454604,
    RecordName: "Fluid & Motion Control Factory - Istanbul,TR",
  },
  {
    Id: 300000659454606,
    RecordName: "Fluid & Motion Control Factory - Johannesburg,ZA",
  },
  {
    Id: 300000389439348,
    RecordName: "Fluid & Motion Control Factory - Laatzen,DE",
  },
  {
    Id: 300000659454601,
    RecordName: "Fluid & Motion Control Factory - Lodz,PL",
  },
  {
    Id: 300000389439346,
    RecordName: "Fluid & Motion Control Factory - Luce,FR",
  },
  {
    Id: 300000659454605,
    RecordName: "Fluid & Motion Control Factory - Skelmersdale,GB",
  },
  {
    Id: 300000206296787,
    RecordName: "Global Flow Controls CSN - Manila, PH",
  },
  {
    Id: 300000206296788,
    RecordName: "Global Isolation Valves CCLS - Manila, PH",
  },
  {
    Id: 300000141759834,
    RecordName: "India Factory,IN",
  },
  {
    Id: 300000141759833,
    RecordName: "India Headquarters",
  },
  {
    Id: 300000141759832,
    RecordName: "India Service Center,IN",
  },
  {
    Id: 300000163950214,
    RecordName: "ISV North America - BU Headquarters",
  },
  {
    Id: 300000047559010,
    RecordName: "LAM Customer Support Center - Escazu,CR",
  },
  {
    Id: 300000047558958,
    RecordName: "LAM Sales Office Buenos Aires,AR",
  },
  {
    Id: 300000389439255,
    RecordName: "LAM Sales Office Final Control Toluca, MX",
  },
  {
    Id: 300000047558966,
    RecordName: "LAM Sales Office Lima,PE",
  },
  {
    Id: 300000047558964,
    RecordName: "LAM Sales Office Mexico DF,MX",
  },
  {
    Id: 300000389439254,
    RecordName: "LAM Sales Office Neuquen, AR",
  },
  {
    Id: 300000047558968,
    RecordName: "LAM Sales Office San Juan,PR",
  },
  {
    Id: 300000047558962,
    RecordName: "LAM Sales Office Santiago,CL",
  },
  {
    Id: 300000047558960,
    RecordName: "LAM Sales Office Sorocaba,BR",
  },
  {
    Id: 300000047558880,
    RecordName: "Latin America Headquarters - AUTOSOL",
  },
  {
    Id: 300000047559036,
    RecordName: "LLLP Office - Round Rock,US",
  },
  {
    Id: 300000047559030,
    RecordName: "Magtech Factory - Houston,US",
  },
  {
    Id: 300002509780285,
    RecordName: "MEA Customer Support Center - Chennai, IN",
  },
  {
    Id: 300002509780511,
    RecordName: "MEA Customer Support Center - Cluj, RO",
  },
  {
    Id: 300002509780286,
    RecordName: "MEA Customer Support Center - Nasik, IN",
  },
  {
    Id: 300002509780293,
    RecordName: "MEA Customer Support Center - Noida, IN",
  },
  {
    Id: 300002509780297,
    RecordName: "MEA Customer Support Center - Pune, IN",
  },
  {
    Id: 300002745923643,
    RecordName: "MEA Customer Support Center EMSS - Quezon City,PH",
  },
  {
    Id: 300002733722447,
    RecordName: "MEA Customer Support Center FRSI - Pasig City,IN",
  },
  {
    Id: 300003363877192,
    RecordName: "MEA Customer Support Center FRSI - Pasig City,PH\t",
  },
  {
    Id: 300000119835734,
    RecordName: "MEA Sales Office Abu Dhabi,AE",
  },
  {
    Id: 300000300481571,
    RecordName: "MEA Sales Office Ahmadi,KW",
  },
  {
    Id: 300000300481576,
    RecordName: "MEA Sales Office Al Jubail, SA",
  },
  {
    Id: 300000300481605,
    RecordName: "MEA Sales Office Al Khobar,SA",
  },
  {
    Id: 300002509780298,
    RecordName: "MEA Sales Office Algiers, DZ",
  },
  {
    Id: 300000300481570,
    RecordName: "MEA Sales Office Basrah, IQ",
  },
  {
    Id: 300000300481567,
    RecordName: "MEA Sales Office Bron Cedex,FR",
  },
  {
    Id: 300000300481578,
    RecordName: "MEA Sales Office Cairo,EG",
  },
  {
    Id: 300000300481573,
    RecordName: "MEA Sales Office Casablanca,MA",
  },
  {
    Id: 300000300481577,
    RecordName: "MEA Sales Office Dhahran Techno Valley,SA",
  },
  {
    Id: 300000300481575,
    RecordName: "MEA Sales Office Doha,QA",
  },
  {
    Id: 300000119835732,
    RecordName: "MEA Sales Office Dubai,AE",
  },
  {
    Id: 300000300481569,
    RecordName: "MEA Sales Office Erbil, IQ",
  },
  {
    Id: 300000659454587,
    RecordName: "MEA Sales Office FLMC Johannesburg, ZA",
  },
  {
    Id: 300000119835733,
    RecordName: "MEA Sales Office Fujairah,AE",
  },
  {
    Id: 300002745917902,
    RecordName: "MEA Sales Office Johannesburg,ZA",
  },
  {
    Id: 300000300481574,
    RecordName: "MEA Sales Office Lagos,NG",
  },
  {
    Id: 300000300481568,
    RecordName: "MEA Sales Office Libreville,GA",
  },
  {
    Id: 300000300481564,
    RecordName: "MEA Sales Office Luanda,AO",
  },
  {
    Id: 300000300481565,
    RecordName: "MEA Sales Office Manama,BH",
  },
  {
    Id: 300000389439192,
    RecordName: "Measurement and Analytical - BU Headquarters",
  },
  {
    Id: 300000047558889,
    RecordName: "Measurement and Analytical Headquarters",
  },
  {
    Id: 300000047559028,
    RecordName: "Micro Motion Factory - Boulder,US",
  },
  {
    Id: 300000047559018,
    RecordName: "Micro Motion Factory - Chihuahua,MX",
  },
  {
    Id: 300000047558885,
    RecordName: "Middle East & Africa Headquarters - AUTOSOL",
  },
  {
    Id: 300000261996408,
    RecordName:
      "NA Customer Support Center Actuation Technologies - Houston,US",
  },
  {
    Id: 300000659454588,
    RecordName:
      "NA Customer Support Center Actuation Technologies - Ontario, US",
  },
  {
    Id: 300000047559006,
    RecordName: "NA Customer Support Center Daniel - Houston,US",
  },
  {
    Id: 300000047559004,
    RecordName: "NA Customer Support Center Magtech - Houston,US",
  },
  {
    Id: 300000047559000,
    RecordName: "NA Customer Support Center Micro Motion - Boulder,US",
  },
  {
    Id: 300000047558988,
    RecordName: "NA Customer Support Center Micro Motion - Escazu,CR",
  },
  {
    Id: 300000335943121,
    RecordName: "NA Customer Support Center MSOL North America - Escazu, CR",
  },
  {
    Id: 300000047558994,
    RecordName: "NA Customer Support Center NARC - Shakopee, US",
  },
  {
    Id: 300000047559002,
    RecordName: "NA Customer Support Center Rosemount Analytical - Houston,US",
  },
  {
    Id: 300000047558992,
    RecordName: "NA Customer Support Center Rosemount Analytical - Manila,PH",
  },
  {
    Id: 300000047558998,
    RecordName: "NA Customer Support Center Rosemount Analytical - Shakopee,US",
  },
  {
    Id: 300000047558990,
    RecordName: "NA Customer Support Center Rosemount Measurement - Manila,PH",
  },
  {
    Id: 300000047558996,
    RecordName:
      "NA Customer Support Center Rosemount Measurement - Shakopee,US",
  },
  {
    Id: 300000335943122,
    RecordName: "NA Customer Support Center Rosemount Nuclear - Chanhassen, US",
  },
  {
    Id: 300003058151509,
    RecordName: "NA Sales Office Emerson Automation Solutions Alabama,US",
  },
  {
    Id: 300003058161202,
    RecordName: "NA Sales Office Emerson Automation Solutions Houston,US",
  },
  {
    Id: 300002733688616,
    RecordName: "NA Sales Office Flow Controls Mansfield MA - Deactivated,US",
  },
  {
    Id: 300002835048009,
    RecordName: "NA Sales Office Flow Controls Mansfield MA,US",
  },
  {
    Id: 300000300481563,
    RecordName: "NA Sales Office Impact Partner Applied Controls Denver,US",
  },
  {
    Id: 300000300481562,
    RecordName: "NA Sales Office Impact Partner Caltrol Las Vegas,US",
  },
  {
    Id: 300000300481561,
    RecordName:
      "NA Sales Office Impact Partner Control Associates Allendale,US",
  },
  {
    Id: 300000300481560,
    RecordName: "NA Sales Office Impact Partner Control Southern Suwanee,US",
  },
  {
    Id: 300000300481559,
    RecordName: "NA Sales Office Impact Partner Cornerstone Cincinnati,US",
  },
  {
    Id: 300000300481558,
    RecordName:
      "NA Sales Office Impact Partner Equipment and Controls ECI Lawrence,US",
  },
  {
    Id: 300000300481557,
    RecordName: "NA Sales Office Impact Partner Experitec St. Louis,US",
  },
  {
    Id: 300000300481556,
    RecordName: "NA Sales Office Impact Partner John H Carter Baton Rouge,US",
  },
  {
    Id: 300000300481546,
    RecordName: "NA Sales Office Impact Partner Lakeside Mississauga,CA",
  },
  {
    Id: 300000300481545,
    RecordName: "NA Sales Office Impact Partner Laurentide Kirkland,CA",
  },
  {
    Id: 300000300481555,
    RecordName: "NA Sales Office Impact Partner NECI Mansfield,US",
  },
  {
    Id: 300000300481554,
    RecordName:
      "NA Sales Office Impact Partner Northeast Controls Clifton Park,US",
  },
  {
    Id: 300000300481553,
    RecordName: "NA Sales Office Impact Partner Novaspect Chanhassen,US",
  },
  {
    Id: 300000300481552,
    RecordName: "NA Sales Office Impact Partner PCE Pacific LDD Bothell,US",
  },
  {
    Id: 300002746032451,
    RecordName: "NA Sales Office Impact Partner Pond Technical Cromwell,US",
  },
  {
    Id: 300003092607194,
    RecordName: "NA Sales Office Impact Partner Pro-Tech Allendale,US",
  },
  {
    Id: 300000300481551,
    RecordName: "NA Sales Office Impact Partner Proconex Royersford,US",
  },
  {
    Id: 300000300481550,
    RecordName: "NA Sales Office Impact Partner Puffer-Sweiven Houston,US",
  },
  {
    Id: 300000389439065,
    RecordName: "NA Sales Office Impact Partner RE Mason Charlotte, US",
  },
  {
    Id: 300000300481549,
    RecordName: "NA Sales Office Impact Partner Scallon Controls Beaumont,US",
  },
  {
    Id: 300000300481544,
    RecordName: "NA Sales Office Impact Partner Spartan Controls Calgary,CA",
  },
  {
    Id: 300000300481548,
    RecordName: "NA Sales Office Impact Partner Stover Marshalltown,US",
  },
  {
    Id: 300000300481547,
    RecordName: "NA Sales Office Impact Partner Vinson Lewisville,US",
  },
  {
    Id: 300002733663592,
    RecordName: "NA Sales Office ISV Reno,US",
  },
  {
    Id: 300000047558906,
    RecordName: "NA Sales Office LLLP Round Rock,US",
  },
  {
    Id: 300002534480531,
    RecordName: "NA Sales Office PWS American Governor, US",
  },
  {
    Id: 300002534480523,
    RecordName: "NA Sales Office PWS Central, US",
  },
  {
    Id: 300002534480525,
    RecordName: "NA Sales Office PWS Northeast, US",
  },
  {
    Id: 300002534480524,
    RecordName: "NA Sales Office PWS Southeast, US",
  },
  {
    Id: 300002534480526,
    RecordName: "NA Sales Office PWS Southwest, US",
  },
  {
    Id: 300002534480528,
    RecordName: "NA Sales Office PWS WEC, US",
  },
  {
    Id: 300002534480527,
    RecordName: "NA Sales Office PWS Western, US",
  },
  {
    Id: 300000659454594,
    RecordName: "PCS Factory - Eden Prairie,US",
  },
  {
    Id: 300000300481593,
    RecordName: "Permasense Ltd Factory - Crawley,GB",
  },
  {
    Id: 300000047558893,
    RecordName: "Power & Water Solutions - BU Headquarters",
  },
  {
    Id: 300000389439200,
    RecordName: "Precision Fluid Control - Head Office",
  },
  {
    Id: 300000389439332,
    RecordName: "Precision Fluid Control Factory - Elk River,US",
  },
  {
    Id: 300000659454608,
    RecordName: "Precision Fluid Control Factory - Pudong,CN",
  },
  {
    Id: 300000389439333,
    RecordName: "Precision Fluid Control Factory - Selmsdorf,DE",
  },
  {
    Id: 300000389439191,
    RecordName: "Pressure Management - BU Headquarters",
  },
  {
    Id: 300000300481587,
    RecordName: "Pressure Management Factory - Armentieres,FR",
  },
  {
    Id: 300000300481582,
    RecordName: "Pressure Management Factory - Black Mountain,US",
  },
  {
    Id: 300000300481589,
    RecordName: "Pressure Management Factory - Bologna,IT",
  },
  {
    Id: 300000300481586,
    RecordName: "Pressure Management Factory - Chartres,FR",
  },
  {
    Id: 300000300481598,
    RecordName: "Pressure Management Factory - Chengdu,CN",
  },
  {
    Id: 300000300481600,
    RecordName: "Pressure Management Factory - Chennai,IN",
  },
  {
    Id: 300000300481591,
    RecordName: "Pressure Management Factory - Cluj,RO",
  },
  {
    Id: 300000300481596,
    RecordName: "Pressure Management Factory - Guadalajara,MX",
  },
  {
    Id: 300000300481592,
    RecordName: "Pressure Management Factory - Manchester,GB",
  },
  {
    Id: 300000300481583,
    RecordName: "Pressure Management Factory - McKinney,US",
  },
  {
    Id: 300000300481595,
    RecordName: "Pressure Management Factory - Nuevo Laredo,MX",
  },
  {
    Id: 300000300481597,
    RecordName: "Pressure Management Factory - QingPu,CN",
  },
  {
    Id: 300000300481581,
    RecordName: "Pressure Management Factory - Stafford,US",
  },
  {
    Id: 300000300481580,
    RecordName: "Pressure Management Factory - Tulsa,US",
  },
  {
    Id: 300000300481579,
    RecordName: "Pressure Management Factory - Walden,US",
  },
  {
    Id: 300000047558891,
    RecordName: "Process Systems & Solutions - BU Headquarters",
  },
  {
    Id: 300000047558904,
    RecordName: "Reliability Solutions - Head Office",
  },
  {
    Id: 300000047559026,
    RecordName: "Rosemount Analytical Factory - Houston, US",
  },
  {
    Id: 300000047559020,
    RecordName: "Rosemount Analytical Factory - Mexicali,MX",
  },
  {
    Id: 300000047559024,
    RecordName: "Rosemount Analytical Factory - Shakopee,US",
  },
  {
    Id: 300000335943127,
    RecordName: "Rosemount and Flow Service Center - Shakopee,US",
  },
  {
    Id: 300000389439330,
    RecordName: "Rosemount Measurement DSI Factory - Boulder,US",
  },
  {
    Id: 300000659454595,
    RecordName: "Rosemount Measurement Factory - Chanhassen,US",
  },
  {
    Id: 300000047559022,
    RecordName: "Rosemount Measurement Factory - Shakopee,US",
  },
  {
    Id: 300000214119835,
    RecordName: "Roxar Flow Measurement Test Factory - Stavanger,NO",
  },
  {
    Id: 300000219774366,
    RecordName: "RTR Factory - Gothenburg,SE",
  },
  {
    Id: 300000294077562,
    RecordName: "SMMC Factory - Singapore,SG",
  },
  {
    Id: 300000213322860,
    RecordName: "Strategic Accounts Team - BU Headquarters",
  },
  {
    Id: 300000047558897,
    RecordName: "Systems & Solutions - Head Office",
  },
  {
    Id: 300000047558887,
    RecordName: "Systems & Solutions Headquarters",
  },
  {
    Id: 300000047558876,
    RecordName: "Test Location",
  },
];
