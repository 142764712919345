import { createSlice } from "@reduxjs/toolkit";

export const basicDataSlice = createSlice({
  name: "basicDataReducer",
  initialState: {
    value: "hello",
  },
  reducers: {
    updateBasicData: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { updateBasicData } = basicDataSlice.actions;

export default basicDataSlice.reducer;

export const basicDataState = (state) => {
  return state.basicDataReducer.value;
};
