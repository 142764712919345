import React, { useState, useEffect } from "react";
import "./selectFieldService.css";
import "../serviceRequestDetails/serviceRequestDetails.jsx";
import siteService from "../../assets/siteService.png";
import troubleshooting from "../../assets/troubleshooting.png";
import Modification from "../../assets/modification.png";
import Warranty from "../../assets/warranty.png";
import FieldServiceIco from "../../assets/FieldService.png";
import training from "../../assets/training.png";
import consultation from "../../assets/consultation.png";
import somethingElse from "../../assets/somethingElse.png";
import { LabelText } from "@emerson/dynamic-ui-public";
import ServiceRequestDetails from "../serviceRequestDetails/serviceRequestDetails.jsx";

const SelectFieldService = (props) => {
  const {
    activeInStep,
    setActiveInStep,
    serviceTypeName,
    serviceName,
    setServiceName,
    userSiteList,
    setSelectedSite,
    selectedSite,
    setPrefSerDate,
    setAddDetails,
    setEndUser,
    setEndUserSiteAddress,
    setStepperDisable,
    setStepMemoize,
    stepMemoize,
    setSysInfoDetails,
  } = props;
  useEffect(() => {
    console.log("userSiteList:", userSiteList);
  }, [userSiteList]);
  const fieldServiceList = [
    { name: "Site Service", image: siteService },
    { name: "Troubleshooting", image: troubleshooting },
    { name: "Calibration", image: FieldServiceIco },
    { name: "Training", image: training },
    { name: "Consultation", image: consultation },
    {
      name: "Something Else",
      image: somethingElse,
    },
  ];
  const RetaProList = [
    { name: "Warranty Replacement", image: Warranty },
    { name: "Repair/Evaluation", image: siteService },
    { name: "Calibration", image: FieldServiceIco },
    { name: "Modification", image: Modification },
    {
      name: "Something Else",
      image: somethingElse,
    },
  ];
  useEffect(() => {
    sessionStorage.removeItem("serviceTypeName");
    setStepperDisable(true);
    setStepMemoize(1);
  }, []);
  useEffect(() => {
    // serviceName === "" ? setStepperDisable(true) : setStepperDisable(false);
  }, [serviceName]);
  return serviceName !== "" ? (
    <ServiceRequestDetails
      userSiteList={userSiteList}
      serviceName={serviceName}
      serviceTypeName={serviceTypeName}
      activeInStep={activeInStep}
      setActiveInStep={setActiveInStep}
      setSelectedSite={setSelectedSite}
      selectedSite={selectedSite}
      setPrefSerDate={setPrefSerDate}
      setServiceName={setServiceName}
      setAddDetails={setAddDetails}
      setEndUserSiteAddress={setEndUserSiteAddress}
      setEndUser={setEndUser}
      setStepperDisable={setStepperDisable}
      setStepMemoize={setStepMemoize}
      stepMemoize={stepMemoize}
      setSysInfoDetails={setSysInfoDetails}
    />
  ) : (
    <div className="selectFieldService">
      <br />
      <br />
      <LabelText text="Service Request Information" textClass="ddl-text--h3" />
      <br />
      <p>
        Please select your desired field service.
        <br />
        <br />
      </p>
      <div className="ServiceRequestBox">
        <div className="serviceType">
          {(
            (serviceTypeName === "Request Field Service" && fieldServiceList) ||
            (serviceTypeName === "Return A Product" && RetaProList)
          )?.map((service) => {
            return (
              <div
                onClick={() => {
                  setServiceName(service.name);
                  setStepMemoize(1);
                }}
              >
                <img src={service.image} alt="Icon" />
                {service.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectFieldService;
